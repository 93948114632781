require("slick-carousel");
require("slick-carousel/slick/slick.css");

jQuery(function(){

	const $ = jQuery;

  if (window.innerWidth < 800) {
		$('.header_desk').remove();

    $('.menu-toggle').on('click', function() {
			$(this).toggle();
      $('.close-toggle').toggle();
      $('.toggling').toggle();
      $('#content').toggle();
    });

    $('.close-toggle').on('click', function() {
      $(this).toggle();
    	$('.menu-toggle').toggle();
    	$('.toggling').toggle();
      $('#content').toggle();
    });

    $('.menu-item-has-children').on('click', function() {
      $(this).find('> ul').toggle();

		  var value = $(this).css('background-image').indexOf('-open');

		  if (value == -1) {
      	$(this).css({'background-image': 'url("/content/uploads/2019/11/arrow-header-mobile-open.png")'})
      }

		  if (value > -1) {
        $(this).css({'background-image': 'url("/content/uploads/2019/10/arrow-header-mobile.png")'})
      }
    });

    $('.onlyMobile').on('click', function() {
      $('.wpsl-select-wrap').toggle();
      $('#wpsl-checkbox-filter').toggle();
      $('.membrefilter').toggle();
      $('.onlyMobile').toggle();
    });
  }

  if (window.innerWidth > 800) {
    $('.header_mobile').remove();
  }

    const listFiltre = document.getElementById('wpsl-checkbox-filter');
    const formSearch = document.querySelector('#wpsl-search-wrap form');
    const itemList = document.querySelectorAll('#wpsl-checkbox-filter li input');

    if (listFiltre) {
        let childDiv= document.createElement("div");
        let childLabel = document.createElement("label");
        let childInput =  document.createElement("input");

        childDiv.setAttribute('class', 'all-filters');
        childInput.setAttribute('type', 'checkbox');
        childInput.setAttribute('name', 'checkbox-all');
        childInput.setAttribute('id', 'checkbox-all');
        childInput.setAttribute('value', '');
        childInput.setAttribute('checked', 'checked');

        childLabel.innerHTML = 'Tous les membres';
        childLabel.prepend(childInput);
        childDiv.appendChild(childLabel);

        formSearch.insertBefore(childDiv, listFiltre);

        childInput.addEventListener('change', function (event) {
            const element = event.target;

            if (element.checked) {
                for (const item of itemList) {
                    item.checked = false;
                }
            }
        });

        for (const item of itemList) {
            item.addEventListener('change', function (event) {
                const element = event.target;

                if (element.checked) {
                    childInput.checked = false;
                }
            });
        }
    }

  $('.wp-caption').each(function(){
    $(this).find('a').attr('target', '_blank');
    $(this).find('a img').removeAttr('srcset');
    $(this).find('a img').attr('src', $(this).find('a').attr('href'));
    $(this).find('figcaption').insertAfter($(this).find('a img'));
    $('<p>'+$(this).find('a img').attr('src').split('/')[Number($(this).find('a img').attr('src').split('/').length - 1)]+'</p>').insertAfter($(this).find('a img'));
  });

  $('.elementor-gallery__container').parents('.elementor-widget-container').append('<div><ul class="slider-for"></ul></div><div><ul class="slider-nav"></ul></div>');

	$('.elementor-gallery__container a').each(function(){
    $(this).parents('.elementor-widget-container').find('.slider-for').append('<li><img src="'+$(this).find('.e-gallery-image').attr('data-thumbnail')+'" /></li>');
    $(this).parents('.elementor-widget-container').find('.slider-nav').append('<li><img src="'+$(this).find('.e-gallery-image').attr('data-thumbnail')+'" /></li>');
  });

	$('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav',
    infinite: true,
    initialSlide: 1,
    draggable: false,
    responsive: [
    	{
        breakpoint: 480,
        settings: {
        	arrows: true,
          prevArrow: "<div class='slick-prev'><i class='sp-pc-font-icon sp-pc-icon-angle-left'></i></div>",
          nextArrow: "<div class='slick-next'><i class='sp-pc-font-icon sp-pc-icon-angle-right'></i></div>"
        }
      }
    ]
  });

	$('.slider-nav').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    infinite: true,
    initialSlide: 1,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
        	slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  $('.slider-nav-thumbnails .slick-slide').removeClass('slick-active');

  // Set active class to first thumbnail slides
  $('.slider-nav-thumbnails .slick-slide').eq(0).addClass('slick-active');

  // On before slide change match active thumbnail to current slide
  $('.slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var mySlideNumber = nextSlide;
    $('.slider-nav-thumbnails .slick-slide').removeClass('slick-active');
    $('.slider-nav-thumbnails .slick-slide').eq(mySlideNumber).addClass('slick-active');
  });

  $('.membres .elementor-widget-wrap .elementor-widget-text-editor').each(function(){
    $('.lightSlideMembre').append($('<li><div class="logo-membre"><img src="'+$(this).find('img').attr('src')+'" /></div><a href="'+$(this).find('a').attr('href')+'" target="_blank">Voir le site</a></li>'))
    $(this).remove();
  });

  $('.lightSlideMembre li').each(function(){
    $(this).insertBefore($('.lightSlideMembre li:nth-child('+Math.floor(Math.random() * Number($('.lightSlideMembre').children().length))+')'))
  });

  $('.lightSlideMembre').slick({
    infinite: true,
    pauseOnFocus: false,
    dots: true,
    pauseOnHover: true,
    slidesToShow: 6,
    speed: 450,
    arrows: true,
    prevArrow: "<div class='slick-prev'><i class='sp-pc-font-icon sp-pc-icon-angle-left'></i></div>",
    nextArrow: "<div class='slick-next'><i class='sp-pc-font-icon sp-pc-icon-angle-right'></i></div>",
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true,
    draggable: true,
    rtl: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
        	slidesToShow: 2,
          slidesToScroll: 2,
          dots: false
        }
      },
      {
      	breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('#wpsl-radius-range').on('change', function(){
		var blk = document.querySelector('.wpsl-dropdown');

		var select = $('#wpsl-radius-dropdown option');
		var selectCurrent = $('#wpsl-radius-dropdown option[value="' + $(this).val() + '"]');

		select.removeAttr('selected');
		selectCurrent.attr('selected', true);

		var selectItem = $('.wpsl-selected-item');
		var selectDropdownItems = $('.wpsl-selected-dropdown li');
		var selectDropdownItem = $('.wpsl-selected-dropdow li[data-value="' + $(this).val() + '"]');

		selectItem.attr('data-value', $(this).val());
		selectItem.text($(this).val() + ' km');
		selectDropdownItems.removeClass('wpsl-selected-dropdown');
		selectDropdownItem.addClass('wpsl-selected-dropdown');

		var title = $('#wpsl-search-wrap .wpsl-select-wrap h3 span');

		title.text($(this).val() + ' Km');
  });

  var inputSearch = $('#wpsl-wrap .wpsl-input #wpsl-search-input');

  function inputDisabled (inputSearchvar) {
      var inputSearch = inputSearchvar;
      var blkRange = $('#wpsl-wrap .wpsl-select-wrap');
      var inputRange = $('#wpsl-wrap .wpsl-select-wrap input');
      var blkListAll = $('#wpsl-wrap .all-filters');
      var inputListAll = $('#wpsl-wrap .all-filters input');
      var blkCheckbox = $('#wpsl-wrap #wpsl-checkbox-filter');
      var inputCheckbox = $('#wpsl-wrap #wpsl-checkbox-filter input');

      if (inputSearch.val() != "") {
          blkRange.removeClass('disable');
          blkCheckbox.removeClass('disable');
          blkListAll.removeClass('disable');
          inputRange.removeAttr('disabled');
          inputCheckbox.removeAttr('disabled');
          inputListAll.removeAttr('disabled');
      } else {
          blkRange.addClass('disable');
          blkCheckbox.addClass('disable');
          blkListAll.addClass('disable');
          inputRange.attr('disabled', 'disabled');
          inputCheckbox.attr('disabled', 'disabled');
          inputListAll.attr('disabled', 'disabled');
      }
  }

  inputDisabled(inputSearch);

  inputSearch.on('keypress', function () {
      inputDisabled(inputSearch);
  });

  inputSearch.on('change', function () {
      inputDisabled(inputSearch);
  });


  var $selector = '.wpsl-info-window .mail, .wpsl-store-location .mail';

  $(document).on('click', $selector, function(e){
      e.preventDefault();

      var btn = $(this);

      navigator.clipboard.writeText(btn.data('email')).then(function() {
          btn.addClass('clicked');
          btn.text('E-mail copié');
      }, function() {
      });

  });

  $('.warning-intranet p:last-child').on('click', function() {
  	$('.warning-intranet p:nth-child(n+2)').toggle();
  });

  $('.warning-intranet p:first-child').on('click', function() {
  	$('.warning-intranet p:nth-child(n+2)').toggle();
  });

  $('.open-popin p').on('click', function() {
  	$('.popin.search-pdf').toggle();
  });

  $('.popin.search-pdf .zmdi-close').on('click', function() {
    $('.popin.search-pdf').toggle();
  });

  if ( (window.innerWidth < 768) && ($('body').hasClass('home')) ) {
  	$('.first-right').insertBefore('.first-left');
    $('.second-right').insertAfter('.second-left');
    $('.third-right').insertBefore('.third-left');
  }

  if ($('body').hasClass('page-template-default', 'page')) {
    $('.elementor-section-wrap > .elementor-element').each(function(){
      if ($(this).find('.elementor-row').children().length == 2) {
        $(this).find('>.elementor-container').css({
          'max-width':'100%'
        });
      }
    });
  }

  /*const formSearch = document.querySelector('#wpsl-search-wrap form');

  if (formSearch) {
      formSearch.addEventListener('submit', function (event) {
          console.log('toto');

          return false;
      });
  }*/


	// Try HTML5 geolocation.

/*var map = $('#wpsl-gmap');

if (navigator.geolocation) {
	const btnLocalisation = document.getElementById('btn-localisation');

	btnLocalisation.addEventListener('click', function (event) {
		event.preventDefault();

		navigator.geolocation.getCurrentPosition(function(position) {
	  	var pos = {
	  		lat: position.coords.latitude,
	    	lng: position.coords.longitude
	  	};

	  	infoWindow.setPosition(pos);
			infoWindow.setContent('Location found.');
	  	infoWindow.open(map);
	  	map.setCenter(pos);
		}, function() {

		});
	});
} else {
  // Browser doesn't support Geolocation
  handleLocationError(false, infoWindow, map.getCenter());
}*/

  // var variable = [];
  // jQuery('#wpsl-stores ul li').each(function(){
  //     variable.push(jQuery(this).height());
  //     variable.sort();
  // })
  // console.log(variable);

});
